var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12 md:col-12"},[_c('div',{staticClass:"card"},[_c('Panel',{staticClass:"mt-3",attrs:{"header":"Finanças"}},[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"operacao"}},[_vm._v("Operação")]),_c('Dropdown',{class:{
                'p-invalid': _vm.submitted && _vm.v$.operacao.$invalid,
              },attrs:{"id":"operacao","options":_vm.operacoes,"placeholder":"Selecione uma operação","disabled":_vm.desativarCampo()},model:{value:(_vm.operacao),callback:function ($$v) {_vm.operacao=$$v},expression:"operacao"}}),(_vm.submitted && _vm.v$.operacao.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo operação é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"dataEntidade"}},[_vm._v("Data de Referência")]),_c('Calendar',{class:{
                'p-invalid': _vm.submitted && _vm.v$.dataReferencia.$invalid,
              },attrs:{"view":"month","dateFormat":"mm/yy","maxDate":_vm.dataMaxima,"disabled":_vm.desativarCampo()},on:{"input":_vm.adicionarMesEAnoReferencia},model:{value:(_vm.dataReferencia),callback:function ($$v) {_vm.dataReferencia=$$v},expression:"dataReferencia"}}),(_vm.submitted && _vm.v$.dataReferencia.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo data é obrigatório. ")]):_vm._e()],1)]),_c('Button',{staticClass:"mt-2 mr-2 p-button-success",attrs:{"type":"submit","autofocus":"","disabled":_vm.loadingOperacao},on:{"click":_vm.aplicarOperacao}},[(_vm.loadingOperacao)?_c('span',[_c('i',{staticClass:"pi pi-spin pi-spinner"}),_c('span',{staticClass:"ml-2"},[_vm._v("Aguarde")])]):_c('span',{staticClass:"ml-2"},[_vm._v("Aplicar")])])],1),_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"value":_vm.historicoOperacao,"paginator":true,"rows":10,"stripedRows":"","rowsPerPageOptions":[5, 10, 15]}},[_c('Column',{attrs:{"field":"id","header":"ID"}}),_c('Column',{attrs:{"field":"operacao","header":"Operação"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [(data.operacao === 'COBRANCA')?_c('Chip',{staticClass:"cobranca custom-chip"},[_vm._v(" Cobrança ")]):_vm._e(),(data.operacao === 'TAXAS')?_c('Chip',{staticClass:"taxas custom-chip"},[_vm._v(" Taxas ")]):_vm._e()]}}])}),_c('Column',{attrs:{"field":"dataReferencia","header":"Data de Referência"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_c('Chip',{staticClass:"data mr-2 mb-2"},[_c('i',{staticClass:"pi pi-calendar icon-spacing"}),_vm._v(" "+_vm._s((data && data.mesReferencia ? String(data.mesReferencia).padStart(2, '0') : '00') + '/' + (data && data.anoReferencia ? data.anoReferencia : '0000'))+" ")])]}}])}),_c('Column',{attrs:{"field":"status","header":"Status"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [(data.status === 'EM_PROCESSAMENTO')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip em-processamento",attrs:{"label":"EM PROCESSAMENTO"}}):_vm._e(),(data.status === 'CONCLUIDO')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip concluido",attrs:{"label":"CONCLUÍDO"}}):_vm._e(),(data.status === 'FALHOU')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip falhou",attrs:{"label":"FALHOU"}}):_vm._e()]}}])}),_c('Column',{attrs:{"field":"auditMetadata","header":"Tempo de Processamento"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [(data.status === 'CONCLUIDO')?_c('Chip',{staticClass:"mr-2 mb-2"},[_c('i',{staticClass:"pi pi-clock icon-spacing"}),_vm._v(" "+_vm._s(_vm.formatDuration( data.auditMetadata.cadastradoEm, data.auditMetadata.alteradoEm ))+" ")]):_vm._e()]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }