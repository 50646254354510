<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <Panel header="Finanças" class="mt-3">
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="operacao">Operação</label>
              <Dropdown
                id="operacao"
                v-model="operacao"
                :class="{
                  'p-invalid': submitted && v$.operacao.$invalid,
                }"
                :options="operacoes"
                placeholder="Selecione uma operação"
                :disabled="desativarCampo()">
              </Dropdown>
              <div v-if="submitted && v$.operacao.$invalid" class="p-error">
                O campo operação é obrigatório.
              </div>
            </div>
            <div class="field col-6 md:col-6">
              <label for="dataEntidade">Data de Referência</label>
              <Calendar
                v-model="dataReferencia"
                :class="{
                  'p-invalid': submitted && v$.dataReferencia.$invalid,
                }"
                view="month"
                dateFormat="mm/yy"
                :maxDate="dataMaxima"
                :disabled="desativarCampo()"
                @input="adicionarMesEAnoReferencia" />
              <div
                v-if="submitted && v$.dataReferencia.$invalid"
                class="p-error">
                O campo data é obrigatório.
              </div>
            </div>
          </div>
          <Button
            type="submit"
            autofocus
            :disabled="loadingOperacao"
            class="mt-2 mr-2 p-button-success"
            @click="aplicarOperacao">
            <span v-if="loadingOperacao">
              <i class="pi pi-spin pi-spinner"></i>
              <span class="ml-2">Aguarde</span>
            </span>
            <span v-else class="ml-2">Aplicar</span>
          </Button>
        </Panel>
        <DataTable
          class="p-datatable-sm"
          :value="historicoOperacao"
          :paginator="true"
          :rows="10"
          stripedRows
          :rowsPerPageOptions="[5, 10, 15]">
          <Column field="id" header="ID"></Column>
          <Column field="operacao" header="Operação">
            <template #body="{ data }">
              <Chip
                v-if="data.operacao === 'COBRANCA'"
                class="cobranca custom-chip">
                Cobrança
              </Chip>
              <Chip v-if="data.operacao === 'TAXAS'" class="taxas custom-chip">
                Taxas
              </Chip>
            </template>
          </Column>
          <Column field="dataReferencia" header="Data de Referência">
            <template #body="{ data }">
              <Chip class="data mr-2 mb-2">
                <i class="pi pi-calendar icon-spacing"> </i>
                {{
                  (data && data.mesReferencia
                    ? String(data.mesReferencia).padStart(2, '0')
                    : '00') +
                  '/' +
                  (data && data.anoReferencia ? data.anoReferencia : '0000')
                }}
              </Chip>
            </template>
          </Column>
          <Column field="status" header="Status">
            <template #body="{ data }">
              <Chip
                v-if="data.status === 'EM_PROCESSAMENTO'"
                label="EM PROCESSAMENTO"
                class="mr-2 mb-2 custom-chip em-processamento" />
              <Chip
                v-if="data.status === 'CONCLUIDO'"
                label="CONCLUÍDO"
                class="mr-2 mb-2 custom-chip concluido" />
              <Chip
                v-if="data.status === 'FALHOU'"
                label="FALHOU"
                class="mr-2 mb-2 custom-chip falhou" />
            </template>
          </Column>
          <Column field="auditMetadata" header="Tempo de Processamento">
            <template #body="{ data }">
              <Chip v-if="data.status === 'CONCLUIDO'" class="mr-2 mb-2">
                <i class="pi pi-clock icon-spacing"> </i>

                {{
                  formatDuration(
                    data.auditMetadata.cadastradoEm,
                    data.auditMetadata.alteradoEm,
                  )
                }}
              </Chip>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import moment from 'moment'
import FinancaOperacoesService from '@/service/FinancaOperacoesService.js'

export default {
  name: 'FinancaOperacoes',

  setup() {
    return { v$: UseVuelidate() }
  },

  validations() {
    return {
      operacao: { required },
      dataReferencia: { required },
    }
  },

  data() {
    return {
      historicoOperacao: [],
      dataReferencia: null,
      anoReferencia: null,
      mesReferencia: null,
      dataMaxima: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      operacao: null,
      operacoes: [],
      submitted: false,
      loadingOperacao: false,
    }
  },

  watch: {
    historicoOperacao() {
      setTimeout(() => {
        this.carregarHistoricoOperacoes()
      }, 30000) // 30000 milissegundos = 30 segundos
    },
  },

  created() {
    this.financaOperacoesService = new FinancaOperacoesService(this.$http)
    this.carregarHistoricoOperacoes()
    this.isProcessamentoAtivo()
  },

  mounted() {
    this.adicionarOperacoes()
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
  },

  methods: {
    adicionarOperacoes() {
      this.operacoes = ['Cobrança', 'Taxas']
    },

    desativarCampo() {
      return this.loadingOperacao
    },

    async aplicarOperacao() {
      const isProcessamentoInativo = !(await this.isProcessamentoAtivo())

      if (this.validate() && isProcessamentoInativo) {
        this.loadingOperacao = true

        const gravarDados = this.financaOperacoesService.gerarOperacao({
          operacao: this.converterOperacao(this.operacao),
          mesReferencia: Number(this.mesReferencia),
          anoReferencia: Number(this.anoReferencia),
        })

        const obterDados = new Promise((resolve) => {
          setTimeout(() => {
            this.carregarHistoricoOperacoes().then(resolve)
          }, 100)
        })

        Promise.all([gravarDados, obterDados])
          .then(() => {
            this.carregarHistoricoOperacoes()
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
          .finally(() => {
            this.loadingOperacao = false
          })
      }
    },

    converterOperacao(operacao) {
      return operacao === 'Cobrança' ? 'COBRANCA' : 'TAXAS'
    },

    carregarHistoricoOperacoes() {
      return this.financaOperacoesService
        .listarHistoricoOperacoes()
        .then((response) => {
          this.historicoOperacao = response
        })
        .catch((error) => {
          console.error('Erro ao carregar o histórico de operações:', error)
        })
    },

    isProcessamentoAtivo() {
      return this.financaOperacoesService
        .existeProcessamentoAtivo()
        .then((response) => {
          this.loadingOperacao = response
          return response
        })
        .catch((error) => {
          console.error('Erro ao verificar o processamento ativo:', error)
        })
    },

    adicionarMesEAnoReferencia() {
      this.mesReferencia = (this.dataReferencia.getMonth() + 1).toString()
      this.anoReferencia = this.dataReferencia.getFullYear().toString()
    },

    validate() {
      this.submitted = true
      return !(this.v$.operacao.$invalid || this.v$.dataReferencia.$invalid)
    },

    formatDuration(startTime, endTime) {
      const duration = moment.duration(moment(endTime).diff(moment(startTime)))
      const hours = String(duration.hours()).padStart(2, '0')
      const minutes = String(duration.minutes()).padStart(2, '0')
      const seconds = String(duration.seconds()).padStart(2, '0')
      return `${hours}:${minutes}:${seconds}`
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.custom-chip {
  font-weight: bold;
  text-align: center;
}
.em-processamento {
  color: #fff;
  background-color: #007bff;
}
.falhou {
  color: #fff;
  background-color: #dc3545;
}
.concluido {
  color: #fff;
  background-color: #28a745;
}
.data {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}
.taxas {
  color: #1f2d3d;
}
.cobranca {
  color: #1f2d3d;
}
.icon-spacing {
  margin-right: 7px;
}
</style>
